.loading {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  &.dark {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.spinner {
  svg {
    fill: #ffffff;
  }
  color: #78be20;
}
