.container-fluid {
  #main-navbar {
    background-color: #25282a;
    color: #fff;
    position: static;
    min-height: 45px;
    border-bottom: 0.5px solid #000;
    background-color: #000;
    #btn-navbar-toggler {
      z-index: 999;
    }
    .club-name {
      width: 100%;
      text-align: center;
      margin-top: 5px;
    }
    .navbar-toggler {
      position: absolute;
      .fa-bars {
        color: #fff;
        font-size: 1.7rem;
      }
      .menu-bar-logout {
        color: #fff;
        font-size: 1rem;
      }
    }
    .navbar-toggler:focus {
      outline: 0;
    }
    & > div[role='navigation'] {
      & > div:first-child {
        position: fixed !important;
        z-index: 2000 !important;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
      }
      div:nth-child(2) {
        z-index: 200 !important;
      }
    }
    .navigation-side-bar {
      right: unset !important;
      bottom: unset !important;
      .account-info {
        display: flex;
        border-bottom: 0.5px solid #000;
        background-color: #000;
        .item {
          margin: 20px 10px;
          padding: 0 10px;
          cursor: pointer;
          position: absolute;
          z-index: 1000;
        }
        .profile {
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
          width: 100%;
          .name {
            display: flex;
            text-align: center;
            justify-content: center;
            span {
              display: block;
              margin: 0 auto;
              font-size: 16px;
              font-weight: bold;
              text-transform: capitalize;
            }
          }
        }
      }
      .sidebar-container {
        .global-nav-container {
          .section-nav-container {
            cursor: pointer;
            line-height: 20px;
            font-size: 14px;
            padding: 6px 0 6px 0;
            .section-title {
              padding-left: 20px;
              width: 100%;
              margin-right: 10%;
              color: rgba(255, 255, 255, 0.7);
              font-weight: bold;
              font-size: 12px;
              text-transform: uppercase;
              border-bottom: 1px solid rgba(137, 141, 141, 0.7);
              cursor: default;
            }
            .section-menu {
              margin: 0;
              width: 100%;
              padding: 12px 0;
              font-size: 15px;
              border-left: 5px solid transparent;
              cursor: pointer;
              &:hover {
                background: rgba(255, 255, 255, 0.25);
              }
              &.active {
                background: rgba(255, 255, 255, 0.25);
                border-color: #fff;
              }
              &.locked {
                opacity: 0.5;
              }
              .secondary-icon {
                &.locked {
                  padding-right: 15px;
                  text-align: right;
                  img {
                    height: 20px;
                    width: 15px;
                  }
                }
              }
            }
            &.secondary {
              &:first-child {
                .section-title {
                  font-size: 18px;
                  margin: 0;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  width: 100%;
                  color: white;
                  text-align: center;
                  cursor: default;
                  .item {
                    position: absolute;
                    display: inline;
                    left: 0;
                    padding: 0 10px;
                    cursor: pointer;
                  }
                  .title {
                    text-align: center;
                    text-transform: none;
                    cursor: default;
                  }
                }
              }
            }
          }
        }
      }
      .app-version-tag {
        width: 100%;
        text-align: center;
        .version,
        .locale {
          font-size: 12px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.76);
          display: block;
          .country-frag {
            height: 12px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .container-fluid {
    #main-navbar {
      min-height: 80px;
    }
  }
}
