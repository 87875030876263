html {
  scroll-behavior: smooth;
  scrollbar-face-color: #004297;
  scrollbar-arrow-color: #004297;
  scrollbar-track-color: #f5f5f5;
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #004297;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #004197c0;
  }
  body {
    font-family: "Roboto", sans-serif;
    scrollbar-face-color: #004297;
    scrollbar-arrow-color: #004297;
    scrollbar-track-color: #f5f5f5;
    .container-fluid {
      padding: 0;
      margin: 0;
      overflow-x: hidden;
      .loading {
        width: 100%;
        height: 100%;
        margin-left: 0%;
      }
      #app-container {
        .page-body {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(100vh - 46px);
          .loading {
            width: 100%;
            height: 100%;
            margin-left: 0%;
          }
        }
        &.login {
          .page-body {
            overflow-y: auto;
            overflow-x: auto;
            height: 100%;
          }
        }
      }
    }
    a {
      color: inherit;
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
    #toast-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px;
      .toast {
        background-color: #25282a;
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: flex;
        opacity: 1;
        justify-content: center;
        &.toast-error {
          background-color: #fd0202d4;
        }
        .toast-message {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
}

.row {
  padding: 0;
  margin: 0;
}

@media (max-width: 767.98px) {
  html {
    body {
      .container-fluid {
        #app-container {
          .page-body {
            height: calc(100vh - 80px);
          }
        }
      }
    }
  }
}